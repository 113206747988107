const routes = {
  home: '/',
  landingPage: '/',
  organ: '/organ-exp',
  analysis: '/analysis',
  search: '/search',
  contact: '/contact',
  literatureTracking: '/literature-tracking',
  help: '/help',
  login: '/login',
  logout: '/logout',
  generateReport: '/generate-report',
  adminRegister: '/admin/register',
  adminLogin: '/admin/login',
  adminHome: '/admin/home',
  fallback: '/*'
};

const navBarDisplayNames = ['测序数据分析','药物筛选', '类器官实验结果', '文献追踪', '成员介绍'];
const navBarKeys = ['analysis', 'search', 'organ', 'literatureTracking', 'contact'];
export const navTabsDataForHeader = (() => {
  return navBarDisplayNames.map((displayName, i) => ({
    title: displayName,
    link: routes[navBarKeys[i]]
  }))
})();

const routerUrls = {
  ...routes,
  searchRowDetail: {
    getRoute: (id) => `/search/detail/${id}`,
    route: `/search/detail/:id`
  }
};

export default routerUrls
