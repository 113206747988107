import React from "react";
import { Button, Upload, Card } from "antd";

const { Dragger } = Upload;


const Analysis = () => {
  const uploadProps = {
    name: "file",
    multiple: false,
  };

  return (
    <div>
      <h2 style={{fontSize: 24, lineHeight: '1.5'}}>测序数据分析</h2>

      <Card
        title="请上传测序原始数据"
        bordered
      >
        <Dragger {...uploadProps}>
          <p className="ant-upload-text" style={{
            height: 200,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>点击或拖拽文件到此区域上传</p>
        </Dragger>

        <div style={{marginTop: 16, textAlign: "right"}}>
          <Button type="primary" style={{marginRight: 8}}>
            上传
          </Button>
          <Button>取消</Button>
        </div>
      </Card>

      <Card
        title="测序结果下载"
        bordered
        style={{marginTop: 20}}
      >
        <div
          style={{textAlign: "right"}}
        >
          <Button type="primary">下载</Button>
        </div>
      </Card>
    </div>
  );
};

export default Analysis;
