import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import caibo from './pictures/caibo.png';
import leishijun from './pictures/leishijun.png';
import lixiaoqiong from './pictures/lixiaoqiong.png';
import luxiaohuan from './pictures/luxiaohuan.png';
import wangguobin from './pictures/wangguobin.png';
import wangxinyue from './pictures/wangxinyue.png';
import wangzheng from './pictures/wangzheng.png';
import xuluming from './pictures/xuluming.png';
import zoudanyi from './pictures/zoudanyi.png';
import {Typography} from "@material-ui/core";
import {fade} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  pageTitle: {
    padding: theme.spacing(2),
    fontWeight: 700,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  card: {
    width: `calc(100% - ${theme.spacing(2) * 2}px)`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: fade(theme.palette.primary.main, 0.08),
    borderRadius: 15,
    overflow: 'hidden',
    margin: theme.spacing(2, 2),
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${theme.spacing(1) * 2}px)`,
      margin: theme.spacing(1, 0),
      padding: theme.spacing(1),
      flexDirection: 'column',
    }
  },
  avatar: {
    '& img': {
      width: 'auto',
      height: 'auto'
    },
    padding: theme.spacing(2),
    borderRadius: 15,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    }
  },
  content: {
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 2, 1, 2),
      width: `calc(100% - ${theme.spacing(2) * 2}px)`,
    }
  },
  name: {
    fontWeight: 700,
    width: '100%',
    textAlign: 'start',
    margin: theme.spacing(0, 0, 2, 0),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      margin: theme.spacing(1, 0),
    }
  }
}));

const data = [
  {
    image: wangzheng,
    name: '王征',
    text: '医学博士，教授，主任医师，博士生导师，教育部“青年长江”学者、湖北省“楚天学者”特聘教授、湖北省“百人计划”专家。现任华中科技大学协和医院胃肠外科副主任，科研处副处长。主要研究方向为肿瘤侵袭转移机制研究、抗肿瘤药物纳米药物、胃癌和结直肠癌的精准治疗等。本项目负责人，全面负责整个项目的实施。'
  },
  {
    image: xuluming,
    name: '徐鲁明',
    text: '于2016年获得华中科技大学博士学位，2016年至2018年担任博士后。他目前是华中科技大学附属协和医院组织工程和再生医学研究中心的研究助理。他的研究重点是基因组学、生物信息学和生物工程技术在胃肠道疾病中的应用。本项目主要承担测序捕获探针的设计和有机培养体系的建立等工作。'
  },
  {
    image: zoudanyi,
    name: '邹丹祎',
    text: '2020年博士毕业于华中科技大学。现就职于华中科技大学同济医学院协和医院检验科。主要从事肿瘤易感相关遗传因素研究及公共数据库构建。本项目主要负责网站设计与维护。'
  },
  {
    image: wangxinyue,
    name: '王星月',
    text: '华中科技大学临床检验诊断学2017级硕士，本科就读于广西医科大学医学检验专业。主要从事高通量测序研究，包括二代和三代测序，在本项目承担高通量测序及数据采集工作。'
  },
  {
    image: wangxinyue,
    name: '王萌',
    text: '华中科技大学'
  },
  {
    image: wangxinyue,
    name: '刘文宇',
    text: '华中科技大学'
  },
];

const Contact: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root} >
      <Typography variant={'h5'} component={'h2'} className={classes.pageTitle}>
        成员介绍
      </Typography>
      {
        data.map((obj, i) => (
          <div key={i} className={classes.card}>
            <div className={classes.avatar}>
              <img src={obj.image} alt={'头像'} />
            </div>
            <div className={classes.content}>
              <Typography variant={'h5'} component={'h3'} className={classes.name}>
                { obj.name }
              </Typography>
              <Typography variant={'body2'}>
                { obj.text }
              </Typography>
            </div>
          </div>
        ))
      }
    </div>
  )
};

export default Contact
