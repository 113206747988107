import React from "react";
import { Button, Upload, Card } from "antd";

const { Dragger } = Upload;


const Organ = () => {
  const uploadProps = {
    name: "file",
    multiple: false,
  };

  return (
    <div>
      <h2 style={{fontSize: 24, lineHeight: '1.5'}}>类器官实验结果</h2>
    </div>
  );
};

export default Organ;
