import React from 'react';
import {useCallback, useEffect, useMemo, useState} from "react";
import axios from "axios";
// @ts-ignore
import Papaparse from 'papaparse';
import {Table, Typography} from "antd";

const LiteratureTracking = () => {
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, total: 0 });
  const [dataItems, setDataItems] = useState([]);

  const loadData = useCallback(async () => {
    if (dataItems.length > 0) {
      return;
    }

    setLoading(true);
    const res = await axios.get('https://hust-tongji-lab.oss-us-west-1.aliyuncs.com/lynch-fapdb/literature-tracking-data.csv', {
      headers: {
        'Accept-Encoding': 'gzip'
      },
    });

    const csvString = res.data;

    if (!csvString) {
      setLoading(false);
      return;
    }

    try {
      const parsedCsv = Papaparse.parse(csvString, {
        header: true,
      });

      if (parsedCsv.data.length > 0) {
        setDataItems(parsedCsv.data);
      }
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  }, [dataItems]);

  const columns = useMemo(() => {
    if (dataItems.length === 0) {
      return [];
    }

    const colNames = Object.keys(dataItems[0]);
    const columns = [];

    for (const colName of colNames) {
      const column: any = {
        title: colName,
        dataIndex: colName,
      };

      switch (colName) {
        case 'PMID': {
          column.fixed = 'left';
          column.render = (value: any) => {
            return <a href={`https://pubmed.ncbi.nlm.nih.gov/${value}/`} target="_blank" rel="noreferrer" style={{ color: '#46978e' }}>{value}</a>;
          };
          break;
        }

        case 'Title': {
          column.width = 400;
          column.render = (value: any) => {
            return <Typography.Paragraph
              ellipsis={{
                rows: 2,
                expandable: true,
                symbol: <span style={{ color: '#46978e' }}>查看</span>,
              }}
            >
              {value}
            </Typography.Paragraph>
          };
          break;
        }

        case 'Authors': {
          column.width = 500;
          column.render = (value: any) => {
            return <Typography.Paragraph
              ellipsis={{
                rows: 2,
                expandable: true,
                symbol: <span style={{ color: '#46978e' }}>查看</span>,
              }}
            >
              {value}
            </Typography.Paragraph>
          };
          break;
        }

        case 'Citation': {
          column.width = 400;
          column.render = (value: any) => {
            return <Typography.Paragraph
              ellipsis={{
                rows: 2,
                expandable: true,
                symbol: <span style={{ color: '#46978e' }}>查看</span>,
              }}
            >
              {value}
            </Typography.Paragraph>
          };
          break;
        }
      }

      columns.push(column);
    }

    return columns;
  }, [dataItems]);

  const tableOnchange = (pagination: any) => {
    const { current } = pagination;
    setPagination(pre => ({ ...pre, current }));
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div>
      <h2 style={{ fontSize: 24, lineHeight: '1.5' }}>最新研究进展</h2>

      <Table
        columns={columns}
        dataSource={dataItems}
        bordered
        size='small'
        loading={loading}
        pagination={{
          pageSize: 10,
          size: 'default',
          showSizeChanger: false,
          total: dataItems.length,
          current: pagination.current,
        }}
        style={{
          margin: '16px 0',
        }}
        onChange={tableOnchange}
        rowKey='_id'
        scroll={{
          x: 'max-content',
        }}
      />

      <p className='text-16 mt-24'>
        备注：<br/>PubMed 文献搜索关键词： "chemotherapy drug", or "targeted therapy" or "cancer treatment", or "tumor
        treatment";<br/> 搜索条件：近一年的临床1-4期试验。
      </p>
    </div>
  );
};

export default LiteratureTracking;
